import {Button} from "@mui/material";
import {PanelBottomOpen, PanelLeftOpen} from "lucide-react";
import React from "react";

export const ShowDescriptionButton = ({mobileView, hide, toggled, onToggle}) => {
    if(toggled || hide) {
        return null
    } else {
        return (
            <Button
                variant={"contained"}
                onClick={onToggle}
                startIcon={mobileView ? <PanelBottomOpen /> : <PanelLeftOpen />}
                sx={{
                    textTransform: "none",
                    backgroundColor: "black",
                    position: "absolute",
                    bottom: 16,
                    left: 16,
                    zIndex: theme => theme.zIndex.drawer + 1
                }}
            >
                Vis beskrivelse
            </Button>
        )
    }
}