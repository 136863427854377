import {Button} from "@mui/material";
import {PanelBottomClose, PanelLeftClose} from "lucide-react";
import React from "react";

export const HideDescriptionButton = ({hide, toggled, onToggle, mobileView}) => {
    if(toggled && !hide) {
        return (
            <Button
                variant={"text"}
                onClick={onToggle}
                startIcon={mobileView ? <PanelBottomClose/> : <PanelLeftClose />}
                sx={{
                    ml: "16px",
                    textTransform: "none",
                    color: "black"
                }}
            >
                Skjul beskrivelse
            </Button>
        )
    } else {
        return null
    }
}