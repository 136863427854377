import {
    AudioTypes,
    HotspotTypes,
    PortalTypes,
    VideoTypes,
} from "../helpers/enums";

export const initialize = (data) => ({...data, domes: []});

export const addDome = (data, dome) => ({
    ...data,
    domes: [...data.domes, dome],
});

export const addDomes = (data, domes) => ({
    ...data,
    domes: [...data.domes, ...domes],
});

export const addMap = (data, map) => ({
    ...data,
    maps: data?.maps ? [...data.maps, map] : [map],
})

export const addMaps = (data, maps) => ({
    ...data,
    maps: data?.maps ? [...data.maps, ...maps] : maps,
})

export const deleteDome = (data, domeId) => ({
    ...data,
    domes: data.domes
        .filter((d) => domeId !== d.id)
        .map((d, i) => ({...d, order: i + 1})),
});

export const deleteMap = (data, mapId) => ({
    ...data,
    maps: data.maps
        .filter((d) => mapId !== d.id)
        .map((d, i) => ({...d, order: i + 1})),
})

export const updateDome = (data, domeId, dome) => {
    const exists = data.domes.find((d) => d.id === domeId);
    if (exists) {
        return {
            ...data,
            domes: data.domes.map((d) => (d.id !== domeId ? d : {...d, ...dome})),
        };
    } else {
        throw new Error(`Dome with id: ${domeId} does not exist`);
    }
}

export const updateMap = (data, mapId, map) => {
    const exists = data.maps.find((d) => d.id === mapId);
    if (exists) {
        return {
            ...data,
            maps: data.maps.map((d) => (d.id !== mapId ? d : {...d, ...map})),
        };
    } else {
        throw new Error(`Map with id: ${mapId} does not exist`);
    }
}

export const updateDomeOrder = (data, fromPos, toPos) => {
    const reorderedDomes = data.domes
        .sort((a, b) => a.order - b.order)
        .reduce((acc, d) => {
            if (d.order === fromPos) {
                return [
                    ...acc,
                    {
                        ...d,
                        order: toPos,
                    },
                ];
            } else if (d.order === toPos) {
                return [
                    ...acc,
                    {
                        ...d,
                        order: fromPos,
                    },
                ];
            } else {
                return [...acc, d];
            }
        }, []);
    return {...data, domes: reorderedDomes};
};

export const updateMapOrder = (data, fromPos, toPos) => {
    const reorderedMaps = data.maps
        .sort((a, b) => a.order - b.order)
        .reduce((acc, d) => {
            if (d.order === fromPos) {
                return [
                    ...acc,
                    {
                        ...d,
                        order: toPos,
                    },
                ];
            } else if (d.order === toPos) {
                return [
                    ...acc,
                    {
                        ...d,
                        order: fromPos,
                    },
                ];
            } else {
                return [...acc, d];
            }
        }, []);
    return {...data, maps: reorderedMaps};
}

export const addHotspotByType = (data, domeId, type, position) => {
    const dome = data.domes.find((d) => d.id === domeId);
    const hotspot = getDefaultHotspotByType(
        dome.hotspots && dome.hotspots.length ? Math.max(...dome.hotspots.map(h => h.id)) + 1 : 1,
        dome.hotspots && dome.hotspots.length ? Math.max(...dome.hotspots.map(h => h.order)) + 1 : 1,
        type,
        position
    );
    return {
        ...data,
        domes: data.domes.map((d) =>
            d.id !== domeId ? d : {...d, hotspots: [...d.hotspots, hotspot]}
        ),
    };
};

export const updateHotspot = (data, domeId, hotspotId, hotspot) => {
    const exists = data.domes
        .find((dome) => dome.id === domeId)
        ?.hotspots.find((a) => a.id === hotspotId);
    if (exists) {
        return {
            ...data,
            domes: data.domes.map((d) =>
                d.id !== domeId
                    ? d
                    : {
                        ...d,
                        hotspots: d.hotspots.map((a) => {
                            if (a.id !== hotspotId) {
                                return a;
                            }
                            if (hotspot === undefined) {
                                return {};
                            }
                            return {...a, ...hotspot};
                        }),
                    }
            ),
        };
    } else {
        throw new Error(
            `Hotspot with domeId: ${domeId} and hotspotId: ${hotspotId} does not exist`
        );
    }
};

export const removeHotspot = (data, domeId, hotspotId) => {
    const exists = data.domes
        .find((d) => d.id === domeId)
        .hotspots.find((a) => a.id === hotspotId);
    if (exists) {
        return {
            ...data,
            domes: data.domes.map((d) =>
                d.id !== domeId
                    ? d
                    : {
                        ...d,
                        hotspots:
                            d.hotspots.length === 1
                                ? []
                                : [...d.hotspots.filter((a) => a.id !== hotspotId)],
                    }
            ),
        };
    } else {
        throw new Error(
            `Hotspot with domeId: ${domeId}, hotspotId: ${hotspotId}, does not exist`
        );
    }
};

export const removePortalsToDome = (data, domeId) => {
    const filteredDomes = data.domes.map((d) => ({
        ...d,
        hotspots: d.hotspots.filter(
            (h) => !(h.type === HotspotTypes.PORTAL && h.domeId === domeId)
        ),
    }));
    return {...data, domes: filteredDomes};
};

const getDefaultHotspotByType = (id, order, type, position) => {
    switch (type) {
        case HotspotTypes.PORTAL: {
            return {
                id,
                type,
                order: order,
                position,
                portalType: PortalTypes.FLOOR,
                size: 0.6,
            };
        }
        case HotspotTypes.TEXT: {
            return {
                id,
                type,
                order: order,
                position,
                content: {
                    description: "",
                },
            };
        }
        case HotspotTypes.IMAGE: {
            return {
                id,
                type,
                order: order,
                position,
                content: {
                    images: [
                        {
                            image: "",
                            title: "",
                            description: "",
                            order: 0
                        }
                    ]
                },
            };
        }
        case HotspotTypes.VIDEO: {
            return {
                id,
                type,
                order: order,
                position,
                videoType: VideoTypes.DMS,
                content: {
                    video: "",
                    youtube: "",
                },
            };
        }
        case HotspotTypes.AUDIO: {
            return {
                id,
                type,
                order: order,
                position,
                audioType: AudioTypes.DMS,
                content: {
                    audio: "",
                    rss: "",
                },
            };
        }
        default:
            return undefined;
    }
};
