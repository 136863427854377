import {TextField} from "../../components/TextField";
import {Upload} from "../../components/Upload";
import {PhotoLibraryOutlined} from "@mui/icons-material";
import React from "react";
import {useThreeSixtyProps, useThreeSixtyTranslation} from "../../App/ThreeSixtyContext";
import {createUseStyles} from "react-jss";
import {useDmsImage} from "../../media/useDmsImage";
import {useImagesDispatch} from "./Images";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import {ArrowDown, ArrowUp, Trash} from "lucide-react";

const useStyles = createUseStyles({
    previewImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
});

export const SingleImage = ({numberOfImages, order, title, description, image}) => {
    const classes = useStyles()
    const {onMediaSelect} = useThreeSixtyProps()
    const t = useThreeSixtyTranslation()
    const {
        onChange,
        onMoveUp,
        onMoveDown,
        onRemove
    } = useImagesDispatch()

    const previewImageUrl = useDmsImage({
        id: image,
    })

    const handleChangeDescription = value => {
        onChange(order, "description", value)
    }

    const handleChangeTitle = value => {
        onChange(order, "title", value)
    }

    const handleMoveUp = () => {
        onMoveUp(order)
    }

    const handleMoveDown = () => {
        onMoveDown(order)
    }

    const handleRemove = () => {
        onRemove(order)
    }

    const setImage = () => {
        if (onMediaSelect) {
            onMediaSelect("image", (dmsId) => {
                onChange(order, "image", dmsId);
            });
        } else {
            onChange(order, "image", "013Aitiph5MJ");
            console.warn("onMediaSelect is undefined");
        }
    };

    return (
        <Stack
            sx={{
                mt: 1,
                mb: 2
            }}
        >
            <Stack
                direction={"row"}
                spacing={0}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Typography
                    variant={"subtitle1"}
                >
                    {t(
                        'imageNumber',
                        'Bilde #{{order}}',
                        {
                            order: order + 1
                        }
                    )}
                </Typography>
                <Box>
                    <IconButton
                        disabled={0 === order}
                        onClick={handleMoveUp}
                        size={"small"}
                    >
                        <ArrowUp/>
                    </IconButton>
                    <IconButton
                        disabled={order + 1 === numberOfImages}
                        onClick={handleMoveDown}
                        size={"small"}
                    >
                        <ArrowDown/>
                    </IconButton>
                    <IconButton
                        disabled={1 === numberOfImages}
                        onClick={handleRemove}
                        color={"error"}
                        size={"small"}
                    >
                        <Trash />
                    </IconButton>
                </Box>
            </Stack>
            <TextField
                id="title-textfield"
                label={t("titleLabel", "Bildetittel")}
                variant="filled"
                sx={{margin: "10px 0"}}
                multiline
                minRows={4}
                maxRows={6}
                value={title}
                onChange={handleChangeTitle}
            />
            <TextField
                id="description-textfield"
                label={t("descriptionLabel", "Beskrivelse")}
                variant="filled"
                sx={{margin: "10px 0"}}
                multiline
                minRows={4}
                maxRows={6}
                value={description}
                onChange={handleChangeDescription}
            />
            <Upload
                file={image}
                Icon={PhotoLibraryOutlined}
                type="image"
                buttonTooltip={t("uploadImage", "Velg bilde")}
                onClick={setImage}
            >
                <img
                    className={classes.previewImage}
                    src={previewImageUrl}
                    alt="preview"
                />
            </Upload>
        </Stack>
    )
}