import React from "react";
import { VolumeUp, DeleteOutline, ExpandMore } from "@mui/icons-material";
import { createUseStyles } from "react-jss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from "@mui/material";

import { TextField } from "../../components/TextField";
import {
  useThreeSixtyProps,
  useThreeSixtyTranslation,
} from "../../App/ThreeSixtyContext";
import { updateHotspot } from "../../App/service";
import { AudioTypes } from "../../helpers/enums";
import { RssTab } from "./RssTab";
import { DmsTab } from "./DmsTab";
import {HighlightHotspotCheckbox} from "../hotspot/HighlightHotspotCheckbox";
import {WhiteHotspotCheckbox} from "../hotspot/WhiteHotspotCheckbox";
import {PreviewHotspotButton} from "../PreviewHotspotButton";

const useStyles = createUseStyles({
  icon: { marginRight: "5px" },
});

export const EditAudio = ({
  hotspot,
  dome,
  expanded,
  onExpandChange,
  onDelete,
}) => {
  const { content, title, audioType, showLabel, whiteBackground, highlight } = hotspot;
  const t = useThreeSixtyTranslation();
  const { onChange, data } = useThreeSixtyProps();
  const classes = useStyles();

  const update = (key, value) => {
    onChange(
      updateHotspot(data, dome.id, hotspot.id, {
        [key]: value,
      })
    );
  };

  const updateContent = (key, value) => {
    update("content", { ...hotspot.content, [key]: value });
  };

  return (
    <Accordion
      disableGutters
      elevation={5}
      square
      sx={{ display: "flex", flexDirection: "column" }}
      expanded={expanded === hotspot?.id.toString()}
      onChange={onExpandChange(hotspot?.id.toString())}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <VolumeUp className={classes.icon} />
        <Typography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "85px",
            overflow: "hidden",
          }}
        >
          {hotspot?.title || "-No title-"}
        </Typography>
        <Typography sx={{ color: "#00000099", marginLeft: "5px" }}>
          {t("interactionType.audio", "Audio")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
        <HighlightHotspotCheckbox
            value={highlight}
            onChange={checked => update('highlight', checked)}
        />
        <WhiteHotspotCheckbox
            value={whiteBackground}
            onChange={checked => update('whiteBackground', checked)}
        />
        <TextField
          id="label-textfield"
          label={t("labelLabel", "Label")}
          variant="filled"
          value={title}
          onChange={(value) => update("title", value)}
          sx={{ margin: "10px 0" }}
          slotProps={{
            htmlInput: {
              maxLength: 25
            }
          }}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showLabel || false}
                onChange={(e) => update("showLabel", e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t("showLabelLabel", "Vis label")}
          />
        </FormGroup>
        <TextField
          id="description-textfield"
          label={t("descriptionLabel", "Beskrivelse")}
          variant="filled"
          sx={{ margin: "10px 0" }}
          multiline
          minRows={4}
          maxRows={6}
          value={content?.description}
          onChange={(value) => updateContent("description", value)}
        />
        <FormControl>
          <FormLabel id="radioButtonGroupAudioType">
            {t("audioType", "Audio type")}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="radioButtonGroupAudioType"
            name="radioButtonGroupAudioType"
            value={audioType}
            onChange={(e) => update("audioType", e.target.value)}
          >
            <FormControlLabel
              value={AudioTypes.DMS}
              control={<Radio />}
              label="DMS"
            />
            <FormControlLabel
              value={AudioTypes.RSS}
              control={<Radio />}
              label="RSS Podcast"
            />
          </RadioGroup>
        </FormControl>
        {audioType === AudioTypes.DMS ? (
          <DmsTab
              content={content}
              updateContent={updateContent}
          >
            <TextField
                id="audio-title"
                label={t("audioTitle", "Overskrift for lydfil")}
                variant="filled"
                sx={{ margin: "10px 0" }}
                multiline
                minRows={4}
                maxRows={6}
                value={content?.audioTitle}
                onChange={(value) => updateContent("audioTitle", value)}
                placeholder={t('audioTitlePlaceholder', "Start avspilling av lyd")}
            />
            <TextField
                id="audio-description"
                label={t("audioDescription", "Beskrivelse for lydfil")}
                variant="filled"
                sx={{ margin: "10px 0" }}
                multiline
                minRows={4}
                maxRows={6}
                value={content?.audioDescription}
                onChange={(value) => updateContent("audioDescription", value)}
                placeholder={t('audioDescriptionPlaceholder', "Klikk for å spille av lyd i bakgrunnen")}
            />
          </DmsTab>
        ) : (
          <RssTab content={content} updateContent={updateContent} />
        )}
        <PreviewHotspotButton />
        <Button
          sx={{ marginTop: "20px" }}
          variant="outlined"
          color="error"
          onClick={onDelete}
        >
          <DeleteOutline />
          {t("deleteTextHotspot", "Slett Hotspot")}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};
