import React from "react";
import {DomeAndHotspotSettings} from "./DomeAndHotspotSettings";
import {useTabState} from "../Sidebar/tabContext";
import {useThreeSixtyProps} from "../App/ThreeSixtyContext";
import {MapSettingsBar} from "../map/MapSettingsBar";
import {Box, useTheme} from "@mui/material";


export const SettingsBar = () => {
    const {tab} = useTabState()
    const {data} = useThreeSixtyProps()

    const content = {
        0: <DomeAndHotspotSettings/>,
        1: <MapSettingsBar />
    }

    if (0 === tab && !data?.domes?.length) {
        return null
    } else if (1 === tab && !data?.maps?.length) {
        return null
    } else {
        return (
            <Box
                sx={{
                    minWidth: "387px",
                    maxWidth: "387px",
                    paddingTop: "17px",
                    overflowY: "auto",
                    backgroundColor: "grey.100",
                }}
            >
                {content[tab]}
            </Box>
        )
    }
}
