import React from "react";
import {ShowDescriptionButton} from "./ShowDescriptionButton";
import {HideDescriptionButton} from "./HideDescriptionButton";

export const DescriptionToggleButton = ({mobileView, hide, toggled, onToggle}) => {
    return (
        <>
            <ShowDescriptionButton
                mobileView={mobileView}
                hide={hide}
                toggled={toggled}
                onToggle={onToggle}
            />
            <HideDescriptionButton
                hide={hide}
                toggled={toggled}
                onToggle={onToggle}
                mobileView={mobileView}
            />
        </>
    )
}