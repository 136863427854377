import {createContext, useCallback, useContext, useMemo} from "react";

const DispatchContext = createContext(undefined)

export const Images = ({images, legacy, onChange, children}) => {
    const handleChangeImage = (order, key, value) => {
        if(legacy) {
            onChange(
                "images",
                [
                    {
                        order: 0,
                        description: images[0].description,
                        title: images[0].title,
                        image: images[0].image,
                        [key]: value
                    }
                ]
            )
        } else {
            onChange(
                "images",
                [
                    ...images.filter(i => i.order !== order),
                    {
                        order,
                        description: images[order].description,
                        title: images[order].title,
                        image: images[order].image,
                        [key]: value
                    }
                ].sort((a, b) => a.order - b.order)
            )
        }
    }

    const handleMoveUp = order => {
        let currentImage = images.find(i => i.order === order)
        let previousImage = images.find(i => i.order === order - 1)
        currentImage.order = order - 1
        previousImage.order = order
        onChange(
            "images",
            [
                ...images.filter(i => i.order !== order && i.order !== order - 1),
                currentImage,
                previousImage
            ].sort((a, b) => a.order - b.order)
        )

    }

    const handleMoveDown = order => {
        let currentImage = images.find(i => i.order === order)
        let nextImage = images.find(i => i.order === order + 1)
        currentImage.order = order + 1
        nextImage.order = order
        onChange(
            "images",
            [
                ...images.filter(i => i.order !== order && i.order !== order + 1),
                currentImage,
                nextImage
            ].sort((a, b) => a.order - b.order)
        )
    }

    const handleRemoveImage = order => {
        const imagesBefore = images.filter(i => i.order < order)
        const imagesAfter = images.filter(i => i.order > order).map(image => ({
            ...image,
            order: image.order - 1
        }))
        onChange(
            "images",
            [
                ...imagesBefore,
                ...imagesAfter
            ].sort((a, b) => a.order - b.order)
        )
    }

    const dispatchObject = useMemo(
        () => ({
            onChange: handleChangeImage,
            onMoveUp: handleMoveUp,
            onMoveDown: handleMoveDown,
            onRemove: handleRemoveImage
        }),
        [images]
    )

    return (
        <DispatchContext.Provider
            value={dispatchObject}
        >
            {children}
        </DispatchContext.Provider>
    )
}

export const useImagesDispatch = () => {
    const context = useContext(DispatchContext)
    if (context === undefined) {
        throw new Error('useImagesEvents must be used within a ImagesProvider')
    } else {
        return context
    }
}