import {IconButton} from "@mui/material";
import React from "react";

export const CarouselButton = ({small=false, disabled, children, onClick, sx={}}) => {
    if(disabled) {
        return null
    } else {
        return (
            <IconButton
                size={small ? "small" : "medium"}
                onClick={onClick}
                disabled={disabled}
                sx={{
                    ...sx,
                    position: "absolute",
                    color: "#ECECEC",
                    backgroundColor: "black",
                    border: "#F0EDE6 2px solid",
                    "&:hover, &.Mui-focusVisible": {
                        backgroundColor: "black",
                        border: theme => `${theme.palette.primary.main} 2px solid`
                    }
                }}
            >
                {children}
            </IconButton>
        )
    }
}