import React, {useState} from "react";
import {MuiModal} from "../components/MuiModal";
import {Stack, useMediaQuery, useTheme} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {ImageDescription} from "./ImageDescription";
import {ResponsiveImage} from "./ResponsiveImage";
import {ArrowLeft, ArrowRight} from "lucide-react";
import {CarouselButton} from "./CarouselButton";
import {useThreeSixtyTranslation} from "../App/ThreeSixtyContext";

export const ImageModal = ({annotation}) => {
    const {title, content} = annotation;
    const [showDescription, setShowDescription] = useState(true)

    const theme = useTheme()
    const mdDown = useMediaQuery(theme.breakpoints.down('md'))
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const images = content.images ? content.images : [content]
    const t = useThreeSixtyTranslation()

    const handleClickToggleDescription = () => {
        setShowDescription(!showDescription)
    }

    const handleNextImage = () => {
        setCurrentImageIndex(currentImageIndex + 1)
    }

    const handlePreviousImage = () => {
        setCurrentImageIndex(currentImageIndex - 1)
    }

    const dmsId = images[currentImageIndex].image ? images[currentImageIndex].image : images[currentImageIndex]?.media?.dmsId
    const description = images[currentImageIndex].description
    const imageTitle = images[currentImageIndex].title

    const image = dmsId
        ? (
            <ResponsiveImage
                mediaId={dmsId}
                alt={content?.media?.alt}
                sx={{
                    maxWidth: "100%",
                    alignSelf: "center",
                    objectFit: "contain",
                    margin: "auto",
                    maxHeight: "100%",
                    width: "100%"
                }}
            />
        ) : null


    const dialogTitle = images.length > 1
        ? t(
            'imageDialogTitle',
            '{{title}} - Bilde {{index}} av {{total}}',
            {
                title: title,
                index: currentImageIndex + 1,
                total: images.length
            }
        )
        : title

    return (
        <MuiModal
            contentSx={{
                padding: 0,
                overflowX: "hidden",
                overflowY: "hidden",
            }}
            PaperProps={{
                sx: {
                    height: "100%"
                }
            }}
            fullWidth={true}
            fullScreen={mdDown}
            title={dialogTitle}
        >
            <Grid
                direction={mdDown ? "column-reverse" : "row"}
                container={true}
                sx={{
                    height: "100%",
                    backgroundColor: "#ECECEC",
                    justifyContent: "flex-start",
                    alignItems: mdDown ? "stretch" : "center"
                }}
            >
                <ImageDescription
                    title={imageTitle}
                    description={description}
                    mobileView={mdDown}
                    showDescription={showDescription}
                    onToggle={handleClickToggleDescription}
                />
                <Grid
                    size={mdDown ? 12 : "grow"}
                    sx={{
                        height: mdDown && showDescription && description ? "40%" : "100%",
                        alignSelf: "center",
                    }}
                >
                    <Stack
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            position: "relative"
                        }}
                    >
                        {image}
                        <CarouselButton
                            small={mdDown && showDescription}
                            onClick={handlePreviousImage}
                            disabled={0 === currentImageIndex}
                            sx={{
                                left: 4
                            }}
                        >
                            <ArrowLeft />
                        </CarouselButton>
                        <CarouselButton
                            small={mdDown && showDescription}
                            onClick={handleNextImage}
                            disabled={images.length - 1 === currentImageIndex}
                            sx={{
                                right: 4,
                            }}
                        >
                            <ArrowRight/>
                        </CarouselButton>
                    </Stack>

                </Grid>

            </Grid>
        </MuiModal>
    );
};
