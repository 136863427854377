import React from "react";

import {useThreeSixtyProps, useThreeSixtyTranslation,} from "../../App/ThreeSixtyContext";
import {Upload} from "../../components/Upload";
import {AudioFileOutlined} from "@mui/icons-material";

export const DmsTab = ({content, updateContent, children}) => {
    const {onMediaSelect} = useThreeSixtyProps();
    const t = useThreeSixtyTranslation();

    const setAudio = () => {
        if (onMediaSelect) {
            onMediaSelect("audio", (dmsId) => {
                updateContent("audio", dmsId);
            });
        } else {
            updateContent("audio", "013AitqNDa5w");
            console.warn("onMediaSelect is undefined");
        }
    };
    return (
        <>
            <Upload
                file={content?.audio}
                Icon={AudioFileOutlined}
                type="audio"
                buttonTooltip={t("uploadAudio", "Velg Audio")}
                onClick={setAudio}
            />
            {children}
        </>
    )
}
