import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import { DeleteOutline, ExpandMore, MyLocation } from "@mui/icons-material";
import { createUseStyles } from "react-jss";

import {
  useThreeSixtyProps,
  useThreeSixtyTranslation,
} from "../../App/ThreeSixtyContext";
import { updateHotspot } from "../../App/service";
import { PortalTypes } from "../../helpers/enums";
import { ColorPickerButton } from "../../components/colorPicker/ColorPicker";
import { TextField } from "../../components/TextField";
import {PortalIconSelect} from "../portal/PortalIconSelect";

const useStyles = createUseStyles({
  portalForm: { display: "flex", flexDirection: "column" },
  icon: { marginRight: "5px" },
});

export const EditPortal = ({
  portal,
  dome,
  expanded,
  onExpandChange,
  onDelete,
}) => {
  const { id, domeId, size, portalType, color, label, showLabel, iconId, iconRotation } = portal;
  const t = useThreeSixtyTranslation();
  const { onChange, data } = useThreeSixtyProps();
  const classes = useStyles();

  const update = (key, value) => {
    onChange(updateHotspot(data, dome.id, id, { [key]: value }));
  };

  return (
    <Accordion
      disableGutters
      elevation={5}
      square
      expanded={expanded === id.toString()}
      onChange={onExpandChange(id.toString())}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <MyLocation className={classes.icon} />
        <Typography>
          {data.domes.find((d) => domeId === d.id)?.title}
        </Typography>
        <Typography sx={{ color: "#00000099", marginLeft: "5px" }}>
          {t("interactionType.portal", "Portal")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          id="label-textfield"
          label={t("labelLabel", "Label")}
          variant="filled"
          value={label || ""}
          onChange={(value) => update("label", value)}
          sx={{ margin: "10px 0" }}
          slotProps={{
            htmlInput: {
              maxLength: 25
            }
          }}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showLabel || false}
                onChange={(e) => update("showLabel", e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t("showLabelLabel", "Vis label")}
          />
        </FormGroup>
        <PortalIconSelect
            value={iconId}
            onChange={iconId => update('iconId', iconId)}
        />
        <FormControl variant="filled" sx={{ width: "100%", margin: "10px 0" }}>
          <InputLabel id="select-destination-label">
            {t("selectDestinationLabel", "Destinasjon")}
          </InputLabel>
          <Select
            labelId="select-destination-label"
            id="select-destination-input"
            value={domeId || ""}
            onChange={(e) => update("domeId", e.target.value)}
          >
            {data.domes.map((d) => (
              <MenuItem key={`destination-option-${d.id}`} value={d.id}>
                <Typography>{d.title || d.id}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "100%", margin: "10px 0" }}>
          <FormLabel id="iconRotation-slider">
            {t("iconRotationLabel", "Roter ikon")}
          </FormLabel>
          <Slider
              sx={{ margin: "0 10px", width: "auto" }}
              defaultValue={0}
              min={0}
              max={360}
              getAriaLabel={() => t("rotationAriaLabel", "Rotasjon")}
              value={iconRotation ? iconRotation : 0}
              onChange={(e) => update("iconRotation", e.target.value)}
              step={1}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => value}
              disableSwap
          />
        </FormControl>
        <FormControl sx={{ width: "100%", margin: "10px 0" }}>
          <FormLabel id="iconSize-slider">
            {t("iconSizeLabel", "Skalering ikon")}
          </FormLabel>
          <Slider
            sx={{ margin: "0 10px", width: "auto" }}
            defaultValue={0.6}
            min={0.1}
            max={1.5}
            getAriaLabel={() => t("sizeAriaLabel", "Size")}
            value={size || 0.6}
            onChange={(e) => update("size", e.target.value)}
            step={0.1}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => value}
            disableSwap
          />
        </FormControl>
        <FormControl>
          <FormLabel id="portalType-radio-buttons-group">
            {t("portalTypeLabel", "Plassering")}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="portalType-radio-buttons-group"
            name="portalType-radio-buttons-group-row"
            value={portalType}
            onChange={(e) => update("portalType", e.target.value)}
          >
            {Object.values(PortalTypes).map((type) => (
              <FormControlLabel
                key={`portalTypes.${type}`}
                value={type}
                control={<Radio />}
                label={t(`portalTypes.${type}`)}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>{t("portalColorLabel", "Farge")}</FormLabel>
          <ColorPickerButton
            color={color}
            templateColors={data?.settings?.colors}
            onChange={(color) => update("color", color)}
          />
        </FormControl>
        <Button
          sx={{ marginTop: "20px" }}
          variant="outlined"
          color="error"
          onClick={onDelete}
        >
          <DeleteOutline />
          {t("deletePortalHotspot", "Slett Hotspot")}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};
