import React from "react";
import {Add, DeleteOutline, ExpandMore, PhotoLibraryOutlined,} from "@mui/icons-material";
import {createUseStyles} from "react-jss";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox, Divider,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";

import {useThreeSixtyProps, useThreeSixtyTranslation,} from "../../App/ThreeSixtyContext";
import {updateHotspot} from "../../App/service";
import {TextField} from "../../components/TextField";
import {HighlightHotspotCheckbox} from "../hotspot/HighlightHotspotCheckbox";
import {WhiteHotspotCheckbox} from "../hotspot/WhiteHotspotCheckbox";
import {SingleImage} from "./SingleImage";
import {Images} from "./Images";
import {PreviewHotspotButton} from "../PreviewHotspotButton";

const useStyles = createUseStyles({
    icon: {marginRight: "5px"},
});

export const EditImage = ({
                              hotspot,
                              dome,
                              expanded,
                              onExpandChange,
                              onDelete,
                          }) => {
    const {
        id,
        content,
        title,
        showLabel,
        highlight,
        whiteBackground
    } = hotspot

    const t = useThreeSixtyTranslation();
    const {onChange, data} = useThreeSixtyProps();
    const classes = useStyles()

    const handleAddNewImage = () => {
        // Support legacy data where only one image was allowed.
        if(content.images) {
            updateContent(
                "images",
                [
                    ...content.images,
                    {
                        description: "",
                        title: "",
                        image: "",
                        order: content.images.length
                    }
                ]
            )
        } else {
            updateContent(
                "images",
                [
                    {
                        description: content.description,
                        title: content.title,
                        image: content.image,
                        order: 0
                    },
                    {
                        description: "",
                        title: "",
                        image: "",
                        order: 1
                    }
                ]
            )
        }
    }

    const images = content?.images || [{
        description: content?.description,
        title: content?.title,
        image: content?.image,
        order: 0
    }]

    const update = (key, value) => {
        onChange(
            updateHotspot(data, dome.id, hotspot.id, {
                [key]: value,
            })
        );
    };
    const updateContent = (key, value) => {
        update("content", {...hotspot.content, [key]: value});
    };

    return (
        <Accordion
            disableGutters
            elevation={5}
            square
            sx={{display: "flex", flexDirection: "column"}}
            expanded={expanded === id.toString()}
            onChange={onExpandChange(id.toString())}
        >
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <PhotoLibraryOutlined className={classes.icon}/>
                <Typography
                    sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "85px",
                        overflow: "hidden",
                    }}
                >
                    {hotspot?.title || "-No title-"}
                </Typography>
                <Typography sx={{color: "#00000099", marginLeft: "5px"}}>
                    {t("interactionType.image", "Bilde")}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{display: "flex", flexDirection: "column"}}>
                <HighlightHotspotCheckbox
                    value={highlight}
                    onChange={checked => update('highlight', checked)}
                />
                <WhiteHotspotCheckbox
                    value={whiteBackground}
                    onChange={checked => update('whiteBackground', checked)}
                />
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showLabel || false}
                                onChange={(e) => update("showLabel", e.target.checked)}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        }
                        label={t("showLabelLabel", "Vis label")}
                    />
                </FormGroup>
                <TextField
                    id="label-textfield"
                    label={t("labelLabel", "Label")}
                    variant="filled"
                    value={title}
                    onChange={(value) => update("title", value)}
                    sx={{margin: "10px 0"}}
                    slotProps={{
                        htmlInput: {
                            maxLength: 25
                        }
                    }}
                />
                <Divider
                    sx={{
                        ml: -2,
                        mr: -2
                    }}
                />
                <Images
                    onChange={updateContent}
                    images={images}
                    legacy={!content.images}
                >
                    {images.map(image => (
                        <SingleImage
                            numberOfImages={images.length}
                            order={image.order}
                            key={image.order}
                            title={image.title}
                            description={image.description}
                            image={image.image}
                        />
                    ))}
                    <Button
                        variant={"outlined"}
                        startIcon={<Add />}
                        color={"info"}
                        onClick={handleAddNewImage}
                    >
                        {t('addNewImageButton', "Legg til bilde")}
                    </Button>
                </Images>
                <PreviewHotspotButton />
                <Button
                    sx={{marginTop: "20px"}}
                    variant="outlined"
                    color="error"
                    onClick={onDelete}
                >
                    <DeleteOutline/>
                    {t("deleteImageHotspot", "Slett Hotspot")}
                </Button>
            </AccordionDetails>
        </Accordion>
    );
};
