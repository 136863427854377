import {Button} from "@mui/material";
import React from "react";
import {PREVIEW_HOTSPOT_ENABLED, useThreeSixtyDispatch} from "../App/ThreeSixtyContext";

export const PreviewHotspotButton = () => {
    const dispatch = useThreeSixtyDispatch()

    const handleClick = () => {
        dispatch({
            type: PREVIEW_HOTSPOT_ENABLED
        })
    }

    return (
        <Button
            variant={"outlined"}
            sx={{marginTop: "20px"}}
            onClick={handleClick}
        >
            Forhåndsvis hotspot
        </Button>
    )
}