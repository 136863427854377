import React from "react";
import {MuiModal} from "../components/MuiModal";
import {DialogContentText} from "@mui/material";

export const TextBox = ({ annotation }) => {
  const {title, content} = annotation;
  return (
      <MuiModal
          title={title}
      >
        <DialogContentText
            sx={{
                maxWidth: "md",
                minWidth: "600px",
            }}
        >
          {content?.description}
        </DialogContentText>
      </MuiModal>
  )
};
