import React, { useEffect, useState } from "react";
import { HotspotTypes } from "../helpers/enums";
import { EditPortal } from "./EditPortal/EditPortal";
import { EditText } from "./EditText/EditText";
import {
  SET_MODAL_HOTSPOT, useThreeSixtyDispatch,
  useThreeSixtyProps,
  useThreeSixtyState,
} from "../App/ThreeSixtyContext";
import { EditImage } from "./EditImage/EditImage";
import { EditVideo } from "./EditVideo/EditVideo";
import { removeHotspot } from "../App/service";
import { EditAudio } from "./EditAudio/EditAudio";

export const HotspotSettings = ({ hotspot, dome }) => {
  const { activeHotspot } = useThreeSixtyState();
  const { onChange, data } = useThreeSixtyProps();
  const [expanded, setExpanded] = useState(undefined);
  const dispatch = useThreeSixtyDispatch()

  const handleChange = (panel) => (event, isExpanded) => {
    dispatch({type: SET_MODAL_HOTSPOT, hotspotId: parseInt(panel)})
    setExpanded(isExpanded ? panel : false);
  };

  const onDelete = () => {
    onChange(removeHotspot(data, dome.id, hotspot.id));
  };

  useEffect(() => {
    if (activeHotspot) {
      setExpanded(activeHotspot.toString());
    }
  }, [activeHotspot]);

  const renderHotspot = (hotspot) => {
    switch (hotspot.type) {
      case HotspotTypes.TEXT:
        return (
          <EditText
            hotspot={hotspot}
            dome={dome}
            expanded={expanded}
            onExpandChange={handleChange}
            onDelete={onDelete}
          />
        );
      case HotspotTypes.PORTAL:
        return (
          <EditPortal
            portal={hotspot}
            dome={dome}
            expanded={expanded}
            onExpandChange={handleChange}
            onDelete={onDelete}
          />
        );
      case HotspotTypes.IMAGE:
        return (
          <EditImage
            hotspot={hotspot}
            dome={dome}
            expanded={expanded}
            onExpandChange={handleChange}
            onDelete={onDelete}
          />
        );
      case HotspotTypes.VIDEO:
        return (
          <EditVideo
            hotspot={hotspot}
            dome={dome}
            expanded={expanded}
            onExpandChange={handleChange}
            onDelete={onDelete}
          />
        );
      case HotspotTypes.AUDIO:
        return (
          <EditAudio
            hotspot={hotspot}
            dome={dome}
            expanded={expanded}
            onExpandChange={handleChange}
            onDelete={onDelete}
          />
        );
      default:
        return null;
    }
  };
  return renderHotspot(hotspot);
};
