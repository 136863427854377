import React, {useState} from "react";
import {Box} from "@mui/material";

export const ResponsiveImage = ({sx={}, mediaId, alt}) => {
    const [error, setError] = useState(false)
    const imageUrl = `https://ems.dimu.org/image/${mediaId}`
    const src3072 = `${imageUrl}?dimension=3072 3072w`
    const src2048 = `${imageUrl}?dimension=2048x2048 2048w`
    const src1024 = `${imageUrl}?dimension=1024x1024 1024w`
    const src512 = `${imageUrl}?dimension=512 512w`
    const src1200 = `${imageUrl}?dimension=1200x1200`
    const srcMax = `${imageUrl}?dimension=max`

    const handleImageError = () => {
        setError(true)
    }

    if(error) {
        return (
            <Box
                component={"img"}
                src={srcMax}
                alt={alt}
                sx={sx}
            />
        )
    } else {
        return (
            <Box
                component={"img"}
                srcSet={`${src3072}, ${src2048}, ${src1024}, ${src512}`}
                src={src1200}
                alt={alt}
                onError={handleImageError}
                sx={sx}
            >
            </Box>
        )
    }

}