import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {
    PREVIEW_HOTSPOT_DISABLED,
    SET_MODAL_HOTSPOT,
    useThreeSixtyDispatch,
    useThreeSixtyProps,
    useThreeSixtyState,
} from "../App/ThreeSixtyContext";
import {Close} from "@mui/icons-material";

export const MuiModal = ({fullScreen=false, title = "", children, fullWidth = false, PaperProps = {}, contentSx = {}}) => {
    const dispatch = useThreeSixtyDispatch()
    const {adminMode} = useThreeSixtyProps()
    const {hideDialogs} = useThreeSixtyState()

    const closeHandler = () => {
        if(adminMode && !hideDialogs) {
            dispatch({
                type: PREVIEW_HOTSPOT_DISABLED
            })
        } else {
            dispatch({type: SET_MODAL_HOTSPOT, hotspotId: 0})
        }
    };

    return (
        <Dialog
            open={true}
            onClose={closeHandler}
            maxWidth={"xl"}
            scroll="paper"
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            sx={{
                maxHeight: fullScreen ? "100dvh" : "95dvh",
            }}
            PaperProps={PaperProps}
        >
            <DialogTitle
                sx={{
                    minHeight: 32
                }}
            >
                {title}
                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: "black"
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={contentSx}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};
