import React from "react";
import {IconButton, Stack, Typography} from "@mui/material";
import {PAUSE_TRIGGERED, PLAY_TRIGGERED, useAudioDispatch, useAudioState} from "./audioContext";
import {Pause, Play} from 'lucide-react'
import {useThreeSixtyTranslation} from "../App/ThreeSixtyContext";


export const AudioContent = ({audioTitle, audioDescription}) => {

    const {
        currentEpisode,
        isPodcast,
        playing
    } = useAudioState()

    const dispatch = useAudioDispatch()

    const t = useThreeSixtyTranslation()


    const handlePlay = () => dispatch({type: PLAY_TRIGGERED})

    const handlePause = () => dispatch({type: PAUSE_TRIGGERED})

    const toggleShowControls = () => {
        if(playing) {
            handlePause()
        } else {
            handlePlay()
        }
    }

    const title = () => {
        if(isPodcast) {
            return currentEpisode?.title
        } else {
            return audioTitle || t('audioTitlePlaceholder', "Start avspilling av lyd")
        }
    }

    const description = () => {
        if(isPodcast) {
            return (
                <div dangerouslySetInnerHTML={{__html: currentEpisode?.summary}}/>
            )
        } else {
            return audioDescription || t('audioDescriptionPlaceholder', "Klikk for å spille av lyd i bakgrunnen")
        }
    }

    const buttonIcon = {
        true: (
            <Pause
                fill={true}
            />
        ),
        false: (
            <Play
                fill={true}
            />
        )
    }

    return (
        <Stack
            direction={"row"}
            spacing={2}
            sx={{
                backgroundColor: 'grey.100',
                p: 2,
                alignItems: "flex-start",
                maxWidth: "sm"
            }}
        >
            <IconButton
                onClick={toggleShowControls}
                size={"large"}
                sx={{
                    color: "black",
                    fill: "black",
                    border: "2px solid black"
                }}
            >
                {buttonIcon[playing]}
            </IconButton>
            <Stack
                spacing={1}
            >
                <Typography
                    variant="sub"
                    sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                    }}
                >
                    {title()}
                </Typography>
                <Typography
                    variant="sub"
                    sx={{
                        fontSize: "14px",
                        fontWeight: "normal",
                    }}
                >
                    {description()}
                </Typography>
            </Stack>
        </Stack>
    )
}
