import {useThreeSixtyProps} from "../App/ThreeSixtyContext";
import {Canvas} from "@react-three/fiber";
import {NoToneMapping} from "three";
import {CameraControls} from "../Canvas/CameraControls";
import React, {Suspense} from "react";
import {Html} from "@react-three/drei";
import {PageLoader} from "../components/loading/PageLoader";
import {Dome} from "./Dome";
import {AddHotspotBar} from "../Admin/AddHotspotBar";
import {RenderIfAdminMode} from "../Admin/RenderIfAdminMode";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({

    content: {
        width: "100%",
        position: "relative",

        "& canvas": {
            width: "100% !important",
            height: "100% !important",
        },
    },
})

export const Domes = () => {
    const {data, onChange, adminMode} = useThreeSixtyProps()
    const classes = useStyles()
    const domes = data?.domes

    if(domes?.length > 0) {
        return (
            <div id="canvasContainer" className={classes.content}>
                <Canvas
                    onCreated={({gl}) => {
                        gl.toneMapping = NoToneMapping;
                    }}
                >
                    <CameraControls
                        enablePan={false}
                        enableDamping
                        dampingFactor={0.08}
                        rotateSpeed={-0.5}
                        data={data}
                    />
                    <Suspense
                        fallback={
                            <Html>
                                <PageLoader/>
                            </Html>
                        }
                    >
                        <Dome
                            data={data}
                            onChange={onChange}
                            adminMode={adminMode}
                        />
                    </Suspense>
                </Canvas>
                <PageLoader
                    data={data}
                />
                <RenderIfAdminMode>
                    <AddHotspotBar/>
                </RenderIfAdminMode>
            </div>
        )
    }
}