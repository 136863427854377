import {Box, Divider, Paper, Slide, Stack, Typography} from "@mui/material";
import {DescriptionToggleButton} from "./DescriptionToggleButton";
import Grid from "@mui/material/Grid2";
import React from "react";
import {HideDescriptionButton} from "./HideDescriptionButton";
import {ShowDescriptionButton} from "./ShowDescriptionButton";

export const ImageDescription = ({title, showDescription, mobileView, description, onToggle}) => {

    if (!description) {
        return null
    }


    if (mobileView) {
        return (
            <>
                <Grid
                    size={12}
                    sx={{
                        height: showDescription ? "60%" : "0%",
                    }}
                >
                    <Slide
                        direction={"up"}
                        in={showDescription}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        sx={{
                            height: "100%",
                        }}
                    >

                        <Paper
                            elevation={10}
                            sx={{
                                width: "100%"
                            }}
                        >
                            <Stack
                                sx={{
                                    overflowY: "auto",
                                    height: "calc(100% - 40px)"
                                }}
                            >

                                <Box
                                    sx={{
                                        p: 4,
                                        overflowWrap: "break-word"
                                    }}
                                >
                                    <Typography
                                        variant={"h5"}
                                    >
                                        {title}
                                    </Typography>
                                    {description}
                                </Box>
                            </Stack>
                            <Divider/>
                            <HideDescriptionButton
                                hide={!description}
                                toggled={showDescription}
                                onToggle={onToggle}
                                mobileView={mobileView}
                            />
                        </Paper>


                    </Slide>
                </Grid>
                <ShowDescriptionButton
                    hide={!description}
                    toggled={showDescription}
                    onToggle={onToggle}
                    mobileView={mobileView}
                />

            </>
        )
    } else {
        return (
            <Grid
                size={description && showDescription ? 3 : 0}
                sx={{
                    backgroundColor: "white",
                    height: "100%",
                }}
            >
                <Stack
                    sx={{
                        overflowY: "auto",
                        height: "calc(100% - 40px)"
                    }}
                >
                    <Divider/>

                    <Box
                        sx={{
                            p: 4,
                            overflowWrap: "break-word"
                        }}
                    >
                        <Typography
                            variant={"h5"}
                        >
                            {title}
                        </Typography>
                        {description}
                    </Box>
                </Stack>
                <Divider/>
                <DescriptionToggleButton
                    toggled={showDescription}
                    onToggle={onToggle}
                    hide={!description}
                    mobileView={mobileView}
                />
            </Grid>
        )
    }
}